import LazyLoadedImage from 'Components/shared/LazyLoadedImage';
import { getMetadata } from 'modules/utils/metadata';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	getFileNameFromPath,
	getPhotoFromContext,
	images,
	lowresImages,
	nonBorderedImages,
} from 'modules/images';
import Theme from 'contexts/Theme';

export default () => {
	const [metadata, setMetadata] = useState({});
	const [loaded, setLoaded] = useState(false);
	const theme = useContext(Theme);
	const { collectionId } = useParams();

	const fetchMetadata = async () => {
		const metadata = await getMetadata();

		setMetadata(metadata);
		setLoaded(true);
	};

	useEffect(() => {
		fetchMetadata();
	}, []);

	if (!loaded) {
		return;
	}

	const collectionImages = images[collectionId];
	const collectionLowresImages = lowresImages[collectionId];
	const collectionMetadata = metadata[collectionId];
	const collectionNonBorderedImages = nonBorderedImages[collectionId];

	const imagesMap = collectionImages.map((path, index) => {
		const fileName = getFileNameFromPath(path);

		const img = getPhotoFromContext(`./${fileName}`, collectionImages);
		const nonBorderedImg = getPhotoFromContext(
			`./${fileName}`,
			collectionNonBorderedImages
		);
		const lowresImg = getPhotoFromContext(
			`./${fileName}`,
			collectionLowresImages
		);

		const imageMetadata = collectionMetadata[fileName];

		return (
			<Link
				to={`/photo-view/${collectionId}/${fileName}`}
				className={
					imageMetadata?.orientation === 'landscape'
						? 'col-[span_3_/_auto]'
						: ''
				}>
				<LazyLoadedImage
					key={path}
					notLazy={index === 0}
					src={theme === 'light' ? nonBorderedImg : img}
					width={imageMetadata?.width}
					height={imageMetadata?.height}
					className={`w-full h-full ${
						imageMetadata?.fit === false ? '' : 'object-cover'
					}`}
					alt={path}
					placeholderSrc={lowresImg}
				/>
			</Link>
		);
	});

	return (
		<div className='h-full w-full flex flex-col gap-5 place-items-center p-5'>
			<div className='flex flex-col gap-2 place-items-center'>
				<span className='text-xl'>{collectionMetadata.date}</span>
				<span className='text-xl'>
					{collectionMetadata.description}
				</span>
			</div>
			<div className='grid grid-cols-3 gap-2 sm:gap-[25px] auto-rows-auto grid-flow-dense pb-5'>
				{imagesMap}
			</div>
		</div>
	);
};
