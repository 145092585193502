const getCollections = (context) => {
	let collections = [
		...new Set(context.keys().map((key) => key.split('/')[1])),
	].filter((x) => x !== 'images');

	collections = [
		'2-20-24-TokyoMclaren',
		...collections.filter((x) => x !== '2-20-24-TokyoMclaren'),
	];

	return collections;
};

export const getFileNameFromPath = (path) => {
	const sections = path.split('/')[path.split('/').length - 1].split('.');

	return sections[0] + '.' + sections[2];
};

export const getFileNameFromFilePath = (path) => {
	return path.split('/')[path.split('/').length - 1];
};

const importAll = (context) => {
	let images = {};

	context.keys().forEach((key) => {
		const title = key.split('/')[1];

		if (!images[title]) images[title] = [];

		const image = context(key);
		console.log(getFileNameFromPath(key), key);
		if (
			!images[title].find(
				(x) => getFileNameFromPath(x) === getFileNameFromFilePath(key)
			)
		)
			images[title].push(image);
	});

	return images;
};

export const getPhotoFromContext = (name, context) => {
	const image = context.find(
		(item) =>
			item.split('/')[item.split('/').length - 1].split('.')[0] ===
			name.split('/')[1].split('.')[0]
	);

	return image;
};

export const collections = getCollections(
	require.context(
		'assets/images/photography',
		true,
		/bordered\/.*\.(jpg|png|jpeg)$/
	)
);

// Import images
export const images = importAll(
	require.context(
		'assets/images/photography',
		true,
		/bordered\/.*\.(jpg|png|jpeg)$/
	)
);
export const lowresImages = importAll(
	require.context(
		'assets/images/photography',
		true,
		/lowres\/.*\.(jpg|png|jpeg)$/
	)
);
export const nonBorderedImages = importAll(
	require.context(
		'assets/images/photography',
		true,
		/nonbordered\/.*\.(jpg|png|jpeg)$/
	)
);
